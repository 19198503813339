import axios from 'axios'
import React, { useState } from 'react'
import { ProductCard } from './ProductCard'

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  physical,
  sst,
}) => {
  const [selected, setSelected] = useState(null)

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected]}
        />

        <ProductCard
          cardTitle={'MCPM 2024 Conference Ticket'}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null)
            }
          }}
          handleRadio={() => {
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 6% SST
          </strong>
        )}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={selected === null}
          // disabled={selected === null && selectedEha === null && selectedPharmacy === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  )
}

export default ProductSelection
