import * as React from "react";

const AccessRight = ({ user }) => {
  const renderFlag = (bool) =>
    bool ? (
      <i className="fas fa-check" style={{ color: "green" }}></i>
    ) : (
      <i className="fas fa-times" style={{ color: "red" }}></i>
    );

  return (
    <>
      <div className="mt-3">Event Access Rights:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-2">
          <span className="fw-bold">2-Day Pass:&nbsp;</span>
          {renderFlag(user.eventAccess)}
        </div>
      </div>

      {/* <div className="mt-3">Abstracts Info:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-1">
          <span className="fw-bold">Is Oral Presenter?&nbsp;</span>
          {renderFlag(user.isOralPresenter)}
        </div>
        <small
          className="d-block"
          dangerouslySetInnerHTML={{ __html: user.oralAbs }}
        />
        <hr />
        <div className="mb-1">
          <span className="fw-bold">Is Finalist?&nbsp;</span>
          {renderFlag(user.isFinalist)}
        </div>
        <small
          className="d-block"
          dangerouslySetInnerHTML={{ __html: user.finalistAbs }}
        />
        <div className="mt-1">
          <small>
            <strong>Award Type:</strong> {user.finalistType}
          </small>
        </div>
        <hr />
        <div className="mb-1">
          <span className="fw-bold">Is Winner?&nbsp;</span>
          {renderFlag(user.isWinner)}
        </div>
        <small
          className="d-block"
          dangerouslySetInnerHTML={{ __html: user.winnerAbs }}
        />
      </div> */}
    </>
  );
};

export default AccessRight;
